import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  $("#formProjectMain").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
      beforeSend: function () {
        $("#mainDalee").html(
          '<i class="fa fa-circle-o-notch fa-spin"></i> Подождите'
        );
      },
    })
      .then((data) => {
        console.log(data);
        window.location.replace(`${data.message}`);
      })
      .catch((data) => {
        $("#mainDalee").html("Далее");
        console.log(data);
        notyf.error(data.responseJSON.error);
      });
  });

  $("#mainImgSubmit").on("click", function () {
    $("#mainImgSubmit").html(
      '<i class="fa fa-circle-o-notch fa-spin"></i> Подождите'
    );
  });

  $("#formProjectDesc").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
      beforeSend: function () {
        $("#mainDescSubmit").html(
          '<i class="fa fa-circle-o-notch fa-spin"></i> Подождите'
        );
      },
    })
      .then((data) => {
        notyf.success(
          "Проект успешно создан, вы будете перенаправлены через несколько секунд"
        );
        setTimeout(function () {
          delay(data);
        }, 2000);
      })
      .catch((data) => {
        $("#mainDescSubmit").html("Далее");
        notyf.error(data.responseJSON.error);
      });
  });

  $("#formProjectPay").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        notyf.success(
          "Проект успешно создан, вы будете перенаправлены через несколько секунд"
        );
      })
      .catch((data) => {
        console.log(data);
        notyf.error(data.responseJSON.error);
      });
  });
});

function delay(data) {
  window.location.replace(`${data.message}`);
}
