import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  let element = document.getElementById("verifyError");

  if (element == null) {
    return;
  }

  let token = document.querySelector("input[name=token]");
  var project = document.querySelector("input[name=project_id]");

  let app = new Vue({
    el: "#verifyError",
    data: {
      token: token.value,
    },

    methods: {
      send() {
        let formAction = this.$refs.form.action;

 
        const formData = new FormData();
        formData.append("authenticity_token", this.token);

        const headers = { "Content-Type": "multipart/form-data" };
        axios
          .post(formAction, formData, { headers })
          .then((res) => {
           window.location.reload();

          })
          .catch(function (error) {
            notyf.error(error.response.data);
          });
      },


      send_project() {
         
         
        let formAction = this.$refs.form.action;

 
        const formData = new FormData();
        formData.append("authenticity_token", this.token);
        formData.append("project_id", project.value);

        const headers = { "Content-Type": "multipart/form-data" };
        axios
          .post(formAction, formData, { headers })
          .then((res) => {
           window.location.reload();

          })
          .catch(function (error) {
            notyf.error(error.response.data);
          });
      },

    },
  });
});
