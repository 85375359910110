import { Notyf } from "notyf";
//import "notyf/notyf.min.css";
import { showCommentInput, filterDropdown } from "../custom/home";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  $("#formComment").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
      beforeSend: function () {
        $("#sendComment").html(
          '<i class="fa fa-circle-o-notch fa-spin"></i> Подождите'
        );
      },
    })
      .then((data) => {
        const body = $("input[name='body']");
        body.val("");
        $("#sendComment").html("Опубликовать");
        $("#commentsList").load(" #commentsList > *");
      })
      .catch((data) => {
        console.log(data);
        notyf.error(data.responseJSON.error);
      })
      .done(function () {
      });
  });
});
