import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  let element = document.getElementById("support");

  if (element == null) {
    return;
  }

  let token = document.querySelector("input[name=token]");

  let app = new Vue({
    el: "#support",
    data: {
      support_categories_id: null,
      email: null,
      body: null,
      files: null,
      token: token.value,
    },

    methods: {
      uploadFile(event) {
        this.files = event.target.files;
      },

      send() {
        let formAction = this.$refs.supportForm.action;

        const myInterceptor = axios.interceptors.request.use(
          function (config) {
            $("#sup-send").html(
              '<i class="fa fa-circle-o-notch fa-spin"></i> Подождите'
            );

            return config;
          },
          function (error) {
            notyf.error("Пожалуйста попробуйте позже");
            // Do something with request error
            return Promise.reject(error);
          }
        );

        let unless = (condition, callback) => {
          !condition && callback();
        };

        const formData = new FormData();
        formData.append("authenticity_token", this.token);
        formData.append("support_categories_id", this.support_categories_id);
        formData.append("email", this.email);
        formData.append("body", this.body);

        unless(this.files == null, () => {
          for (const i of Object.keys(this.files)) {
            formData.append("files[]", this.files[i]);
          }
        });

        const headers = { "Content-Type": "multipart/form-data" };
        axios
          .post(formAction, formData, { headers })
          .then((res) => {
            notyf.dismissAll();
            axios.interceptors.request.eject(myInterceptor);
            notyf.success("Успешно");
            $("#sup-send").html("Отправить");
          })
          .catch(function (error) {
            $("#sup-send").html("Отправить");
            console.log(error.response.data);
            notyf.dismissAll();
            notyf.error("Категория не может быть пустой");
          });
      },
    },
  });
});
 