import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

export default class extends Controller {
  static targets = ["source", "modal"];


  open(event) {
    this.modalTarget.classList.toggle("is-active")
  }

  copy(event) {
    event.preventDefault();

    const notyf = this.notyf();
    var content = this.sourceTarget
    content.type = 'text';
    content.select();
    document.execCommand("copy");
    content.type = 'hidden';
    notyf.success("Ссылка скопирована в буффер обмена");
  }

  notyf() {
    return new Notyf({
      duration: 3000,
      position: { x: "left", y: "bottom" },
      ripple: true,
      dismissible: true,
    });
  }
}
