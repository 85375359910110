import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

export default class extends Controller {       
  static targets = [
    "value",
    "csrf",
    "projectId"
  ];

  connect(){
  }

  delete(){
    const notyf = this.notyf();
    const csrf = this.csrfTarget.value
    const link = this.valueTarget.value

    axios
    .post("/delete_link", {
      authenticity_token: csrf,
      link: link,
    })

    this.element.remove()
     
  }

  deleteProjectLinks(){
    const notyf = this.notyf();
    const csrf = this.csrfTarget.value
    const link = this.valueTarget.value
    const prId= this.projectIdTarget.value

    axios
    .post("/delete_project_link", {
      project_id: prId,
      authenticity_token: csrf,
      link: link,
    })

    this.element.remove()
     

  }

  deletePlatform(){
    const notyf = this.notyf();
    const csrf = this.csrfTarget.value
    const link = this.valueTarget.value
    const prId= this.projectIdTarget.value

    axios
    .post("/delete_platform_link", {
      project_id: prId,
      authenticity_token: csrf,
      link: link,
    })

    this.element.remove()
     
  }

  notyf() {
    return new Notyf({
      duration: 3000,
      position: { x: "left", y: "bottom" },
      ripple: true,
      dismissible: true,
    });
  }

}
