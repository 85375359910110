$(document).on("turbo:load", async function () {
  modalInit();
  showHeaderSearch();
  headerMobileMenu();
  headerMobileLanguge();
  headerProfileDropdown();
  headerProfileToggle();
  showCommentInput();
  fileAttachPreviewLogo();
  fileAttachPreviewPoster();
  filterDropdown();
  tooltipDonate();
  logoAnimated();
  scrollToTop();
  scrollToAnchor();
  faqCardCollapse();
  showCommentInputNews();
  showCommentInputNews();
  faqCardCollapse();
  tooltipDonate();
  scrollToAnchor();
  scrollToTop();
  faqCardCollapse();
  notificationViewAll();
  shareDropdown();
  shareQrcode();
  inputPasswordSwitch();
  titleScrollAnimation();
  homeIntroAnimated();
  transactionsCommentToggle();
  showReplyInput();
  editDropdown();

  function fileAttachPreviewLogo() {
    $("#n-new-logo").change(function () {
      const file = this.files[0];
      if (file) {
        let reader = new FileReader();
        reader.onload = function (event) {
          $(".input-file--logo .input-file__load img").attr(
            "src",
            event.target.result
          );
        };
        reader.readAsDataURL(file);
      }
    });
  }

  // Показать/скрыть поля изменения пароля

  function fileAttachPreviewPoster() {
    $("#n-new-picture").change(function () {
      const file = this.files[0];
      if (file) {
        let reader = new FileReader();
        reader.onload = function (event) {
          $(".input-file--picture .input-file__load img").attr(
            "src",
            event.target.result
          );
        };
        reader.readAsDataURL(file);
      }
    });
  }

  function headerMobileLanguge() {
    $(".js-language-select").on("click", function () {
      $(this).toggleClass("is-active");
    });
  }

  // header profile dropdown show/hide
  function headerProfileDropdown() {
    $(".js-header-dropdown-profile").on("click", function () {
      $(".header__profile-dropdown").toggleClass("is-show");
    });
  }

  // header profile toggle show/hide
  function headerProfileToggle() {
    $(".user-profile.user-profile--is-active").on("click", function (e) {
      $(this).toggleClass("user-profile--is-toggle");
      $(".user-profile:not(.user-profile--is-active)").toggleClass(
        "user-profile--is-visible"
      );
      e.preventDefault();
    });
  }

  function showHeaderSearch() {
    $(".js-header-search").on("click", function () {
      $(".header__search").toggleClass("is-show");
    });
  }

  // header menu mobile show/hide
  function headerMobileMenu() {
    $(".js-header-menu-mobile, .js-header-dropdown-profile").on(
      "click",
      function () {
        $("body").toggleClass("is-hidden");
        $(".header__menu-mobile, .header__overlay-mobile").toggleClass(
          "is-show"
        );
      }
    );
  }

  const swiper = new Swiper(".home-projects-slider", {
    slidesPerView: "auto",
    spaceBetween: 23,
    loop: false,
    pagination: {
      el: ".home-projects-pagination",
    },
    navigation: {
      nextEl: ".home-projects-button-next",
      prevEl: ".home-projects-button-prev",
    },

    breakpoints: {
      0: {
        slidesPerView: "auto",
        loop: false,
        spaceBetween: 8,
        pagination: false,
        navigation: false,
      },

      769: {
        spaceBetween: 23,
      },

      1181: {
        slidesPerView: 3,
      },

      1281: {
        navigation: {
          nextEl: ".home-projects-button-next",
          prevEl: ".home-projects-button-prev",
        },
        pagination: {
          el: ".home-projects-pagination",
          type: "bullets",
        },
      },
    },
  });

  // donate page, animation logo
  function logoAnimated() {
    var logoAnimated = $(".js-animated-logo");
    if ($(logoAnimated)[0]) {
      window.setTimeout(function () {
        logoAnimated.addClass("is-loaded");
      }, 4000);
    }
  }

  // file download preview
  function fileAttachPreviewLogo() {
    $("#n-new-logo").change(function () {
      const file = this.files[0];
      if (file) {
        let reader = new FileReader();
        reader.onload = function (event) {
          $(".input-file--logo .input-file__load img").attr(
            "src",
            event.target.result
          );
        };
        reader.readAsDataURL(file);
      }
    });
  }

  function fileAttachPreviewPoster() {
    $("#n-new-picture").change(function () {
      const file = this.files[0];
      if (file) {
        let reader = new FileReader();
        reader.onload = function (event) {
          $(".input-file--picture .input-file__load img").attr(
            "src",
            event.target.result
          );
        };
        reader.readAsDataURL(file);
      }
    });
  }

  // tooltip toggle
  function tooltipDonate() {
    $(".js-tooltip-toggle").on("click", function (e) {
      $(this).parent().toggleClass("is-active");
      e.preventDefault();
    });

    $(".js-tooltip-close").on("click", function (e) {
      $(".tooltip-donate").removeClass("is-active");
      e.preventDefault();
    });
  }

  // scroll anchor faq page
  function scrollToAnchor() {
    $(".js-faq-nav-link-target").click(function (event) {
      var id = $(this).attr("href");
      $(id).addClass("is-flash");
      setTimeout(function () {
        $(id).removeClass("is-flash");
      }, 1400);
      var target = $(id).offset().top;
      $("html, body").animate({ scrollTop: target }, 400);
      event.preventDefault();
    });
  }

  function faqCardCollapse() {
    $(".js-faq-btn-toggle").on("click", function (e) {
      var faqCard = $(this).parents(".faq-nav__item");

      $(".faq-nav__item").not(faqCard).removeClass("is-show");
      faqCard.toggleClass("is-show");
      e.preventDefault();
    });
  }

  // tooltip toggle
  function tooltipDonate() {
    $(".js-tooltip-toggle").on("click", function (e) {
      $(this).parent().toggleClass("is-active");
      e.preventDefault();
    });

    $(".js-tooltip-close").on("click", function (e) {
      $(".tooltip-donate").removeClass("is-active");
      e.preventDefault();
    });
  }

  // scroll anchor faq page
  function scrollToAnchor() {
    $(".js-faq-nav-link-target").click(function (event) {
      var id = $(this).attr("href");
      $(id).addClass("is-flash");
      setTimeout(function () {
        $(id).removeClass("is-flash");
      }, 1400);
      var target = $(id).offset().top;
      $("html, body").animate({ scrollTop: target }, 400);
      event.preventDefault();
    });
  }

  // scroll to top
  function scrollToTop() {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 300) {
        $(".js-scroll-top").addClass("is-show");
      } else {
        $(".js-scroll-top").removeClass("is-show");
      }
    });
    $(".js-scroll-top").click(function (event) {
      var id = $(this).attr("href");
      var target = $(id).offset().top - 100;
      $("html, body").animate({ scrollTop: target }, 400);
      event.preventDefault();
    });
  }

  function faqCardCollapse() {
    $(".js-faq-btn-toggle").on("click", function (e) {
      var faqCard = $(this).parents(".faq-nav__item");

      $(".faq-nav__item").not(faqCard).removeClass("is-show");
      faqCard.toggleClass("is-show");
      e.preventDefault();
    });
  }

  // notification collapse
  function notificationViewAll() {
    $(".js-notification-view-message").click(function (event) {
      $(this).hide();
      $(this).siblings(".notification-card__text").removeClass("text-clamp");
      event.preventDefault();
    });
  }

  // share project dropdown
  function shareDropdown() {
    $(".js-share-btn").on("click", function (e) {
      $(".share-el__dropdown").removeClass("is-qrcode");
      $(this).parent().toggleClass("is-active");
      e.preventDefault();
    });
  }

  // share project dropdown
  function shareQrcode() {
    $(".js-share-qrcode").on("click", function (e) {
      $(this).parents(".share-el__dropdown").toggleClass("is-qrcode");
      e.preventDefault();
    });
  }

  // share project dropdown
  function commentViewInput() {
    $(".js-comment-view-btns").focus(function () {
      var commentCurrentInput = $(this)
        .parents(".comments-input")
        .find(".comments-input-buttons");
      commentCurrentInput.addClass("is-show");
    });
    $(".js-comments-cancel").on("click", function (e) {
      $(".comments-input-buttons").removeClass("is-show");
      e.preventDefault();
    });
  }

  // show/hide password input
  function inputPasswordSwitch() {
    $(".js-input-password-switch").on("click", function () {
      if ($(this).siblings("input").attr("type") == "password") {
        $(this).addClass("is-password-show");
        $(this).siblings("input").attr("type", "text");
      } else {
        $(this).removeClass("is-password-show");
        $(this).siblings("input").attr("type", "password");
      }
    });
  }

  // about title animation
  function titleScrollAnimation() {
    var $classLoop = $(".js-animation-title span"),
      i = 0;
    setInterval(function () {
      $classLoop
        .removeClass("is-active")
        .filter($classLoop[i])
        .toggleClass("is-active");
      i < $classLoop.length - 1 ? i++ : (i = 0);
    }, 2000);
  }

  // home page animation
  function homeIntroAnimated() {
    var homeIntroAnimated = $(".js-home-animated");
    if ($(homeIntroAnimated)[0]) {
      window.setTimeout(function () {
        homeIntroAnimated.addClass("is-loaded");
      }, 222);
    }
  }

  function transactionsCommentToggle() {
    $(".js-transactions-comment-open").on("click", function (e) {
      $(this)
        .parents(".transactions-item")
        .toggleClass("transactions-item--show-comment");
    });
  }

  // mask input card number
  if (document.getElementsByClassName("js-mask-card-number").length > 0) {
    var dateMask = IMask(
      document.getElementsByClassName("js-mask-card-number")[0],
      {
        mask: "0000 0000 0000 0000",
        lazy: true,
      }
    );
  }
});

export function showCommentInput() {
  let commentInputFocused = false;

  // Event listener for focusing on comment input
  $("#commentInput").on("focus", function () {
    // Remove any existing input blocks
    $(".comments-tree__child--input").remove();
    $(".likes-group").removeClass("active");
    $(".likes-answer").attr("area-disabled", "true");

    commentInputFocused = true;
    $("#commentsInputButtons").show(); // Показываем кнопки при фокусе
  });

  // Event listener for clicking on "Ответить"
  $(".comments-list").on("click", ".likes-answer", function (e) {
    e.stopPropagation(); // Остановка всплытия, чтобы не срабатывало document click
    const parent = $(this).closest(".comments-item");
    const value = parent.find("input").val();

    // Remove any existing input blocks (if needed)
    $(".comments-tree__child--input").remove();
    $(".likes-group").removeClass("active");
    $(".likes-answer").attr("area-disabled", "true");

    if ($(this).attr("area-disabled") == "true") {
      // Show input block
      $(this).closest(".likes-group").addClass("active");
      $(this).closest(".comments-item").append(
        `<div data-controller="subComments" class="comments-tree__child comments-tree__child--input">` +
        '<div data-subComments-target="child" class="comments-tree__child">' +
        '<div id="subComments" class="comments-input">' +
        '<div class="comments-input-wrap">' +
        '<div class="comments-item-person">' +
        "</div>" +
        '<div class="input input-no-label">' +
        `<input data-subComments-target="parent" hidden name="parent_id" value="${value}">` +
        `<input data-subComments-target="body" type="text" name="body" placeholder="Написать комментарий">` +
        "</div>" +
        "</div>" +
        '<div class="comments-input-buttons is-show">' +
        '<div class="comments-input-cancel">Отмена</div>' +
        '<button data-action="click->subComments#create" class="comments-input-public btn btn-green">Опубликовать</button>' +
        "</div>" +
        "</div>" +
        "</div>" +
        `</div>`
      );
      $(this).attr("area-disabled", "false");
      $("#commentsInputButtons").hide(); // Скрываем кнопки при открытии блока ответа
      commentInputFocused = false; // Сбрасываем флаг фокуса
    } else {
      // Hide input block
      $(this).closest(".likes-group").removeClass("active");
      $(this).closest(".comments-item").find(".comments-tree__child--input").remove();
      $(this).attr("area-disabled", "true");
    }
  });

  // Event listener for clicking cancel
  $(".comments-list").on("click", ".comments-input-cancel", function (e) {
    e.stopPropagation(); // Остановка всплытия, чтобы не срабатывало document click
    const parent = $(this).closest(".comments-item");
    parent.find(".comments-tree__child--input").remove();
    parent.find(".likes-group").removeClass("active");
    parent.find(".likes-answer").attr("area-disabled", "true");
  });

  // Event listener for clicks outside the input block
  $(document).on("click", function (e) {
    if (!$(e.target).closest(".comments-item").length && !$(e.target).is("#commentInput")) {
      $(".comments-tree__child--input").remove();
      $(".likes-group").removeClass("active");
      $(".likes-answer").attr("area-disabled", "true");
      $("#commentsInputButtons").hide(); // Скрываем кнопки при клике вне комментариев
      commentInputFocused = false; // Сбрасываем флаг фокуса
    } else {
      commentInputFocused = true; // Включаем флаг фокуса, если клик внутри комментариев или на commentInput
    }
  });
}



export function modalInit() {
  $(".js-modal-open").on("click", function (e) {
    var modalName = $(this).attr("href");
    $("body").addClass("is-hidden");
    $(modalName).addClass("is-show");
    e.preventDefault();
  });

  $(".js-modal-close").on("click", function (e) {
    $("body").removeClass("is-hidden");
    $(".modal").removeClass("is-show");
    e.preventDefault();
  });
}

export function filterDropdown() {
  $("#commentsList").on("click", ".js-filter-open", function (e) {
    $(this).parents(".filter-item").toggleClass("is-open");
    e.preventDefault();
  });
}

export function editDropdown() {
  $('.js-filter-open').on('click', function(e) {
    $(this).parents('.filter-item').toggleClass('is-open');
    e.preventDefault();
});
}


  // filter show/hide
/*   function filterDropdown() {
    $('.js-filter-open').on('click', function(e) {
        $(this).parents('.filter-item').toggleClass('is-open');
        e.preventDefault();
    });
} */


export function showCommentInputNews(e) {
  $(".comments-list-news").on("click", ".likes-answer", function (e) {
    const parent = $(this).closest(".comments-item");
    const value = parent.find("input").val();
    const csrf = document.getElementsByName("csrf-token");
    console.log(parent);

    if ($(this).attr("area-disabled") == "true") {
      console.log("true");
      $(this).closest(".likes-group").addClass("active");
      $(this)
        .closest(".comments-item")
        .append(
          `<div data-controller="subCommentsNews">` +
            '<div data-subCommentsNews-target="child" class="comments-tree__child">' +
            '<div id="subComments" class="comments-input">' +
            '<div class="comments-input-wrap">' +
            '<div class="comments-item-person">' +
            "</div>" +
            '<div class="input input-no-label">' +
            `<input data-subCommentsNews-target="parent" hidden name="parent_id" value="${value}">` +
            `<input data-subCommentsNews-target="body" type="text" name="body" placeholder="Написать комментарий">` +
            "</div>" +
            "</div>" +
            '<div class="comments-input-buttons is-show">' +
            '<div class="comments-input-cancel">Отмена</div>' +
            '<button data-action="click->subCommentsNews#create" class="comments-input-public btn btn-green">Опубликовать</button>' +
            "</div>" +
            "</div>" +
            "</div>" +
            `</div>`
        );
      $(this).attr("area-disabled", "false");
    } else {
      $(this).closest(".likes-group").removeClass("active");
      $(this).closest(".comments-item").find(".comments-tree__child").remove();
      $(this).attr("area-disabled", "true");
    }
  });
}

export function showReplyInput(e) {
  $(".comments-list-reply").on("click", ".likes-answer, .edit", function (e) {
    const parent = $(this).closest(".comments-item");
    const value = parent.find("input").val();
    const csrf = document.getElementsByName("csrf-token");
    console.log(parent);

    if ($(this).attr("area-disabled") == "true") {
      console.log("true");
      $(this).closest(".likes-group").addClass("active");
      $(this)
        .closest(".comments-item")
        .append(
          `<div data-controller="reply">` +
            '<div data-reply-target="child" class="comments-tree__child">' +
            '<div id="subComments" class="comments-input">' +
            '<div class="comments-input-wrap">' +
            '<div class="comments-item-person">' +
            "</div>" +
            '<div class="input input-no-label">' +
            `<input data-reply-target="parent" hidden name="parent_id" value="${value}">` +
            `<input data-reply-target="body" type="text" name="body" placeholder="Написать комментарий">` +
            "</div>" +
            "</div>" +
            '<div class="comments-input-buttons is-show">' +
            '<div class="comments-input-cancel">Отмена</div>' +
            '<button data-action="click->reply#create" class="comments-input-public btn btn-green">Опубликовать</button>' +
            "</div>" +
            "</div>" +
            "</div>" +
            `</div>`
        );
      $(this).attr("area-disabled", "false");
    } else {
      $(this).closest(".likes-group").removeClass("active");
      $(this).closest(".comments-item").find(".comments-tree__child").remove();
      $(this).attr("area-disabled", "true");
    }
  });
}
