import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  let element = document.getElementById("verificationDocs");

  if (element == null) {
    return;
  }

  let token = document.querySelector("input[name=token]");

  let app = new Vue({
    el: "#verificationDocs",
    data: {
      link: null,
      files: null,
      token: token.value,
    },

    mounted() {
      $(function () {
        let inputFile = $("#file-attach-verification");
        let button = $(".js-file-attach-verification");
        let filesContainer = $("#attach-files-verification");
        let files = [];

        inputFile.change(function () {
          let newFiles = [];
          for (let index = 0; index < inputFile[0].files.length; index++) {
            let file = inputFile[0].files[index];
            newFiles.push(file);
            files.push(file);
          }

          newFiles.forEach((file) => {
            let fileElement = $(
              `<div class="attach-file s-support-chat__footer-attach-item"><a class="text-overflow attach-file__name" href="#" target="_blank">${file.name}</a><button class="attach-file__btn-remove" type="button"><svg class="u-icon attach-file__btn-remove-icon"><use xlink:href="img/sprite.svg#close"></use></svg> </button> </div>`
            );
            fileElement.data("fileData", file);
            filesContainer.append(fileElement);

            fileElement
              .find(".attach-file__btn-remove")
              .click(function (event) {
                let fileElement = $(event.target);
                let indexToRemove = files.indexOf(fileElement.data("fileData"));
                fileElement.parent().remove();
                files.splice(indexToRemove, 1);
              });
          });
        });

        button.click(function () {
          inputFile.click();
        });
      });
    },
    methods: {
      uploadFile(event) {
        this.files = event.target.files;
      },

      send() {
        let formAction = this.$refs.form.action;

        const myInterceptor = axios.interceptors.request.use(
          function (config) {
            $("#sup-send").html(
              '<i class="fa fa-circle-o-notch fa-spin"></i> Подождите'
            );

            return config;
          },
          function (error) {
            notyf.error("Пожалуйста попробуйте позже");
            // Do something with request error
            return Promise.reject(error);
          }
        );

        let unless = (condition, callback) => {
          !condition && callback();
        };

        const formData = new FormData();

        unless(this.link == null, () => {
          formData.append("link", this.link);
        });

        formData.append("authenticity_token", this.token);

        unless(this.files == null, () => {
          for (const i of Object.keys(this.files)) {
            formData.append("files[]", this.files[i]);
          }
        });

        const headers = { "Content-Type": "multipart/form-data" };
        axios
          .post(formAction, formData, { headers })
          .then((res) => {
            notyf.dismissAll();
            axios.interceptors.request.eject(myInterceptor);
            notyf.success("Успешно");
            $("#sup-send").html("Отправить");
            $(".settings-profile").load(" .settings-profile > *");

          })
          .catch(function (error) {
            $("#sup-send").html("Отправить");
            console.log(error.response.data);
            notyf.dismissAll();
            notyf.error("Категория не может быть пустой");
          });
      },
    },
  });
});
