import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

export default class extends Controller {
  static targets = ["parent", "body", "child"];

  connect() {}

  create() {
    const child = ($(this).closest('.comments-item'))
    const notyf = this.notyf();
    const csrf = document.getElementsByName('csrf-token')[0].getAttribute("content");
    
    axios
      .post("/subcomments_news", {
        authenticity_token: csrf,
        parent_id: this.parentTarget.value,
        body: this.bodyTarget.value,
      })
      .then(function (response) {
        
        $("#commentsList").load(" #commentsList > *");        
      })
      .catch(function (error) {
        notyf.error("Попробуйте еще раз");
      });
  }

  notyf() {
    return new Notyf({
      duration: 3000,
      position: { x: "left", y: "bottom" },
      ripple: true,
      dismissible: true,
    });
  }
}
