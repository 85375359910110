import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  let element = document.getElementById("verifyApproved");

 
  if (element == null) {
    return;
  }

  let app = new Vue({
    el: "#verifyApproved",
    data: {
      url: null,
      testingCode: "1234",

    },

    methods: {
      copyURL() {
       let testingCodeToCopy = document.querySelector('#testing-code')
       testingCodeToCopy.setAttribute("type", "text");
        testingCodeToCopy.select();
        var successful = document.execCommand('copy');
      },
    },
  });
});
