import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  $("#AdminLogin").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        Turbo.visit("/admin/dashboard");
      })
      .catch((data) => {
        notyf.error(data.responseJSON.error);
      });
  });

  $("#UserSignup").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        console.log(data);
        window.location.replace(`/users/${data.message}/donations`);
      })
      .catch((data) => {
        notyf.error(
          Object.keys(data.responseJSON.error)
            .map((e) => " " + e + " " + data.responseJSON.error[e])
            .join("\n")
        );
      });
  });

  $("#CreateAdminForm").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        notyf.success("Успешно");
        Turbo.visit("/admin/dashboard/admins");
      })
      .catch((data) => {
        notyf.error(
          Object.keys(data.responseJSON.error)
            .map((e) => " " + e + " " + data.responseJSON.error[e])
            .join("<br>")
        );
      });
  });

  $("#UserLogin").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        console.log(data.message.id);
        window.location.replace(`/users/${data.message.id}`);
      })
      .catch((data) => {
        notyf.error(data.responseJSON.error);
      });
  });

  $("#ChangePass").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        Turbo.visit("/password/reset/success");
      })
      .catch((data) => {
        notyf.error(data.responseJSON.error);
      });
  });

  $("#EditAdminForm").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "PATCH",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        notyf.success("Успешно");
      })
      .catch((data) => {
        notyf.error(data.responseJSON.error);
      });
  });

  $("#eye-login-pass").click(function () {
    let input = $(this).prev();
    if (input.attr("type") === "password") {
      $(this).children()
        .replaceWith(`<svg xmlns="http://www.w3.org/2000/svg" class="text-gray-300" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.4">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      <path stroke-linecap="round" stroke-linejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
      </svg>`);
    } else {
      $(this).children()
        .replaceWith(`<svg width="24" height="24" class="text-gray-300" stroke="currentColor"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.4">
      <path stroke-linecap="round" stroke-linejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
      </svg>
      `);
    }
    input.attr("type", input.attr("type") === "password" ? "text" : "password");
  });

  $("#AdminDelete").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "DELETE",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        notyf.success("Успешно");
        setTimeout(() => {
          Turbo.visit("/admin/dashboard/admins");
        }, 2000);
      })
      .catch((data) => {
        notyf.error(data.responseJSON.error);
      });
  });


  $("#qrcodeForm").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
      beforeSend: function () {
        $("#sendQr").html(
          '<i class="fa fa-circle-o-notch fa-spin"></i> Подождите'
        );
      },
    })
      .then((data) => {
        $("#sendQr").html("Опубликовать");
        $("#QrList").load(" #QrList > *");
      })
      .catch((data) => {
        notyf.error(data.responseJSON.error);
        $("#sendQr").html("Ошибка");

      })
      .done(function () {
      });
  });


});
