import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  let element = document.getElementById("addCard");

  if (element == null) {
    return;
  }

  let token = document.querySelector("input[name=token]");

  let app = new Vue({
    el: "#addCard",
    data: {
      token: token.value,
      code: null,
    },

    methods: {
      send(event) {
        event.preventDefault();

        let formAction = "/api/add_card";

        axios
          .post(formAction)
          .then((res) => {
            console.log(res.data.message);
            window.location.href = res.data.message;
          })
          .catch(function (error) {
            notyf.error(error.response.data.errors);
          });
      },
    },
  });
});
