import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  let element = document.getElementById("appFormEdit");

  if (element == null) {
    return;
  }

  let prjid = document.querySelector("input[name=project_id]");
  let token = document.querySelector("input[name=token]");

  let app = new Vue({
    el: "#appFormEdit",
    data: {
      id: prjid.value,
      logo: null,
      cover: null,
      content: "image",
      video: null,
      token: token.value,
    },

    
    methods: {
      uploadLogo() {
        console.log(1);
        this.logo = this.$refs.logo.files[0];
        const file = this.logo;
        if (file) {
          let reader = new FileReader();
          reader.onload = function (event) {
            $(".input-file--logo .input-file__load img").attr(
              "src",
              event.target.result
            );
          };
          reader.readAsDataURL(file);
        } 
      },

      uploadCover() {
        this.cover = this.$refs.file.files[0];
        const file = this.cover;
        if (file) {
          let reader = new FileReader();
          reader.onload = function (event) {
            $(".input-file--picture .input-file__load img").attr(
              "src",
              event.target.result
            );
          };
          reader.readAsDataURL(file);
        }
      },

      send() {
        var formAction = this.$refs.imageForm.action;

        const myInterceptor = axios.interceptors.request.use(
          function (config) {
            $("#imgBtn").html(
              '<i class="fa fa-circle-o-notch fa-spin"></i> Подождите'
            );

            return config;
          },
          function (error) {
            notyf.error("Пожалуйста попробуйте позже");
            // Do something with request error
            return Promise.reject(error);
          }
        );

        let unless = (condition, callback) => {
          !condition && callback();
        };

        const formData = new FormData();
        unless(this.logo == null, () => {
          formData.append("logo", this.logo);
        });

        unless(this.cover == null, () => {
          formData.append("cover", this.cover);
        });

        formData.append("content", this.content);

        unless(this.video == null, () => {
          formData.append("video", this.video);
        });

        formData.append("authenticity_token", this.token);
        formData.append("project_id", this.id);

        const headers = { "Content-Type": "multipart/form-data" };
        axios
          .post(formAction, formData, { headers })
          .then((res) => {
            notyf.dismissAll();
            axios.interceptors.request.eject(myInterceptor);
            Turbo.visit(res.data.message)

          })
          .catch(function (error) {
            notyf.dismissAll();
            notyf.error("Пожалуйста попробуйте позже", "Ошибка");
          });
      },
    },
  });
});
