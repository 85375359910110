
$(document).on('turbo:load', function () {

 $("#modalHandler, #closeModalCreateAdmin, #modalWrapper").on( "click", function() {
  $("#modalCreateAdmin").toggle();
});



});
 