import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

export default class extends Controller {
  static targets = ["input", "csrf", "count", "upvote", "downvote"];

  connect() {}

  send() {
    const upvoteSvg = this.upvoteTarget;

    const downVote = this.downvoteTarget;

    const notyf = this.notyf();
    const count = this.countTarget;
    axios
      .post("/project_like", {
        authenticity_token: this.csrfTarget.value,
        project_id: this.inputTarget.value,
      })
      .then(function (response) {
        upvoteSvg.setAttribute("fill", "green");
        downVote.setAttribute("fill", "grey");
        count.textContent = response.data.message;
      })
      .catch(function (error) {
        notyf.error("Голосовать могут только авторизованные пользователи");
      });
  }

  downvote() {
    const upvoteSvg = this.upvoteTarget;

    const downVote = this.downvoteTarget;

    const notyf = this.notyf();
    const count = this.countTarget;
    axios
      .post("/project_dislike", {
        authenticity_token: this.csrfTarget.value,
        project_id: this.inputTarget.value,
      })
      .then(function (response) {
        downVote.setAttribute("fill", "red");
        upvoteSvg.setAttribute("fill", "grey");
        count.textContent = response.data.message;
      })
      .catch(function (error) {
        notyf.error("Голосовать могут только авторизованные пользователи");
      });
  }

  comment_upvote() {
    const upvoteSvg = this.upvoteTarget;

    const downVote = this.downvoteTarget;

    const notyf = this.notyf();
    const count = this.countTarget;
    axios
      .post("/comment_like", {
        authenticity_token: this.csrfTarget.value,
        comment_id: this.inputTarget.value,
      })
      .then(function (response) {
        upvoteSvg.setAttribute("fill", "green");
        downVote.setAttribute("fill", "grey");
        count.textContent = response.data.message;
      })
      .catch(function (error) {
        notyf.error("Голосовать могут только авторизованные пользователи");
      });
  }

  comment_downvote() {
    const upvoteSvg = this.upvoteTarget;

    const downVote = this.downvoteTarget;

    const notyf = this.notyf();
    const count = this.countTarget;

    axios
      .post("/comment_dislike", {
        authenticity_token: this.csrfTarget.value,
        comment_id: this.inputTarget.value,
      })
      .then(function (response) {
        downVote.setAttribute("fill", "red");
        upvoteSvg.setAttribute("fill", "grey");
        count.textContent = response.data.message;
      })
      .catch(function (error) {
        notyf.error("Голосовать могут только авторизованные пользователи");
      });
  }

  post_upvote() {
    const upvoteSvg = this.upvoteTarget;

    const downVote = this.downvoteTarget;

    const notyf = this.notyf();
    const count = this.countTarget;
    axios
      .post("/post_like", {
        authenticity_token: this.csrfTarget.value,
        post_id: this.inputTarget.value,
      })
      .then(function (response) {
        upvoteSvg.setAttribute("fill", "green");
        downVote.setAttribute("fill", "grey");
        count.textContent = response.data.message;
      })
      .catch(function (error) {
        notyf.error("Голосовать могут только авторизованные пользователи");
      });
  }

  post_downvote() {
    const upvoteSvg = this.upvoteTarget;

    const downVote = this.downvoteTarget;

    const notyf = this.notyf();
    const count = this.countTarget;

    axios
      .post("/post_dislike", {
        authenticity_token: this.csrfTarget.value,
        post_id: this.inputTarget.value,
      })
      .then(function (response) {
        downVote.setAttribute("fill", "red");
        upvoteSvg.setAttribute("fill", "grey");
        count.textContent = response.data.message;
      })
      .catch(function (error) {
        notyf.error("Голосовать могут только авторизованные пользователи");
      });
  }

  donate_upvote() {
    const upvoteSvg = this.upvoteTarget;

    const downVote = this.downvoteTarget;

    const notyf = this.notyf();
    const count = this.countTarget;
    axios
      .post("/donate_like", {
        authenticity_token: this.csrfTarget.value,
        id: this.inputTarget.value,
      })
      .then(function (response) {
        upvoteSvg.setAttribute("fill", "green");
        downVote.setAttribute("fill", "grey");
        count.textContent = response.data.message;
      })
      .catch(function (error) {
        notyf.error("Голосовать могут только авторизованные пользователи");
      });
  }

  donate_downvote() {
    const upvoteSvg = this.upvoteTarget;

    const downVote = this.downvoteTarget;

    const notyf = this.notyf();
    const count = this.countTarget;

    axios
      .post("/donate_dislike", {
        authenticity_token: this.csrfTarget.value,
        id: this.inputTarget.value,
      })
      .then(function (response) {
        downVote.setAttribute("fill", "red");
        upvoteSvg.setAttribute("fill", "grey");
        count.textContent = response.data.message;
      })
      .catch(function (error) {
        notyf.error("Голосовать могут только авторизованные пользователи");
      });
  }

  report_upvote() {
    const upvoteSvg = this.upvoteTarget;

    const downVote = this.downvoteTarget;

    const notyf = this.notyf();
    const count = this.countTarget;
    axios
      .post("/report_like", {
        authenticity_token: this.csrfTarget.value,
        report_id: this.inputTarget.value,
      })
      .then(function (response) {
        upvoteSvg.setAttribute("fill", "green");
        downVote.setAttribute("fill", "grey");
        count.textContent = response.data.message;
      })
      .catch(function (error) {
        notyf.error("Голосовать могут только авторизованные пользователи");
      });
  }

  report_downvote() {
    const upvoteSvg = this.upvoteTarget;

    const downVote = this.downvoteTarget;

    const notyf = this.notyf();
    const count = this.countTarget;

    axios
      .post("/report_dislike", {
        authenticity_token: this.csrfTarget.value,
        report_id: this.inputTarget.value,
      })
      .then(function (response) {
        downVote.setAttribute("fill", "red");
        upvoteSvg.setAttribute("fill", "grey");
        count.textContent = response.data.message;
      })
      .catch(function (error) {
        notyf.error("Голосовать могут только авторизованные пользователи");
      });
  }

  notyf() {
    return new Notyf({
      duration: 3000,
      position: { x: "left", y: "bottom" },
      ripple: true,
      dismissible: true,
    });
  }
}
