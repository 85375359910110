import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
 
 $("#100button").on('click',function(){
  $("#amount").val('100')
 })

 $("#500button").on('click',function(){
  $("#amount").val('500')
 })

 $("#1000button").on('click',function(){
  $("#amount").val('1000')
 })

 $("#sucbutton").on('click',function(){
  $("#donmessageinput").val('Мне нравится ваш проект!')
 })

 $("#thankbutton").on('click',function(){
  $("#donmessageinput").val('Спасибо!')
 })

 $("#okbutton").on('click',function(){
  $("#donmessageinput").val('Удачи!')
 })


});
