import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

export default class extends Controller {
  static targets = [
    "csrf",
    "logo",
    "cover",
    "linksName",
  ];

  connect() {
  }

  notyf() {
    return new Notyf({
      duration: 3000,
      position: { x: "left", y: "bottom" },
      ripple: true,
      dismissible: true,
    });
  }


  send() {
    const notyf = this.notyf();

    axios
      .post("/project_builds/build_main", {
        authenticity_token: this.csrfTarget.value,
        logo: this.logoTarget.value,
        cover: this.coverTarget.value,

      })
      .then(function (response) {
        notyf.success("Успешно обновлено");
      })
      .catch(function (error) {
        notyf.error("Попробуйте еще раз");
      });
  }


}

