import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  $("#confirmPhone").on("click", "#sendSms", function (e) {
    e.preventDefault();

    $(".phoneTimer").css("display", "flex");
     
    $(".seconds").text(30);

    var form = $("#confirmPhone");

    $("#sendSms").attr("disabled", true);
    var actionUrl = "/sendsms";

    timer();

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
        $("#binding-phone-input").attr("disabled", true);
        console.log(data);
      })
      .catch((data) => {
        notyf.error(data.responseJSON.error);
      });
  });

  $("#confirmPhone").on("click", "#confirmSms", function (e) {
    e.preventDefault();

    var form = $("#confirmPhone");

    var actionUrl = "/confirmsms";

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
    })
      .then((data) => {
       notyf.success("Номер успешно подтвержден");
       $('.modal').removeClass('is-show');
       window.location.reload();

      })
      .catch((data) => {
        notyf.error("Произошла ошибка, попробуйте ещё раз");
      });
  });
});

function timer() {
  var _Seconds = $(".seconds").text(),
    int;
  int = setInterval(function () {
    // запускаем интервал
    if (_Seconds > 0) {
      _Seconds--; // вычитаем 1
      $(".seconds").text(_Seconds); // выводим получившееся значение в блок
    } else {
      clearInterval(int);
      $("#sendSms").attr("disabled", false);
      $("#binding-phone-input").attr("disabled", false);
      $(".phoneTimer").hide();
    }
  }, 1000);
}
