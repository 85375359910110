import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

export default class extends Controller {
  static targets = ["input", "csrf", "button"];

  connect() {}

  create() {
    const notyf = this.notyf();
    const button = this.buttonTarget;

    axios
      .post("/users_subscriptions", {
        authenticity_token: this.csrfTarget.value,
        followed_id: this.inputTarget.value,
      })
      .then(function (response) {
        $("#subsBlock").load(" #subsBlock > *");
        $("#subsBlockHead").load(" #subsBlockHead > *");

        notyf.success("Вы успешно подписались");
      })
      .catch(function (error) {
        notyf.error("Попробуйте еще раз");
      });
  }

  destroy() {
    const notyf = this.notyf();
    const button = this.buttonTarget;

    axios
      .delete(`/users_subscriptions/${this.inputTarget.value}`, {
        data: {
          authenticity_token: this.csrfTarget.value,
          followed_id: this.inputTarget.value,
        },
      })
      .then(function (response) {
        $("#subsBlock").load(" #subsBlock > *");
        $("#subsBlockHead").load(" #subsBlockHead > *");
        notyf.success("Вы успешно отписались");
      })
      .catch(function (error) {
        notyf.error("Попробуйте еще раз");
      });
  }

  project() {
    console.log(this.csrfTarget.value);
    const notyf = this.notyf();
    const button = this.buttonTarget;

    axios
      .post("/projects_subscriptions", {
        authenticity_token: this.csrfTarget.value,
        followed_id: this.inputTarget.value,
      })
      .then(function (response) {
        button.classList.remove("btn-green");
        button.classList.add("btn-white");
        button.textContent = "Вы подписаны";
        notyf.success("Вы успешно подписались");
      })
      .catch(function (error) {
        notyf.error("Попробуйте еще раз");
      });
  }

  notyf() {
    return new Notyf({
      duration: 3000,
      position: { x: "left", y: "bottom" },
      ripple: true,
      dismissible: true,
    });
  }
}
