import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

export default class extends Controller {
  static targets = [
    "name",
    "lastName",
    "about",
    "csrf",
    "city",
    "links",
    "linksName",
    "linksLink",
    "linksContainer",
    "imageSrc",
    "slug",
    "AnotherlinksContainer"
  ];

  connect() {
    console.log(this.linksTargets.length);
  }

  add() {
    this.linksContainerTarget.insertAdjacentHTML("beforeend", this.content());
  }

  notyf() {
    return new Notyf({
      duration: 1000000,
      position: { x: "left", y: "bottom" },
      ripple: true,
      dismissible: true,
    });
  }

  addAnother() {
    this.AnotherlinksContainerTarget.insertAdjacentHTML("beforeend", this.content());
  }

  content() {
    const close = this.imageSrcTarget.src;
    const csrf = this.csrfTarget.value;
    return `
    <div data-controller="removeLinks" data-editForm-target="links" class="input-group-social">
    <div class="input input-social-name">
      <input data-removeLinks-target="id" name="id" hidden value="">
      <input data-removeLinks-target="csrf" name="authenticity_token" type="hidden" value="${csrf}" />

      <label for="#profile-social-name">Название ссылки</label>
      <input data-editForm-target="linksName" value="" id="profile-social-name" type="text" name="#profile-social-name" placeholder="Например: Вконтакте">
    </div>
    <div class="input input-social-link">
      <label for="#profile-social-link">URL</label>
      <input data-removeLinks-target="value" data-editForm-target="linksLink" value="" id="profile-social-link" type="text" name="#profile-social-link">
    </div>
    <div data-action="click->removeLinks#delete" class="input-delete"><img src="${close}" alt=""></div>
  </div>

    `;
  }

  send() {
    const notyf = this.notyf();

    let linksTitle = [];
    let links = [];

    this.linksNameTargets.forEach((element) => {
      linksTitle.push(element.value);
    });

    this.linksLinkTargets.forEach((element) => {
      links.push(element.value);
    });

    axios
      .post("/update_user", {
        authenticity_token: this.csrfTarget.value,
        first_name: this.nameTarget.value,
        last_name: this.lastNameTarget.value,
        city_id: this.cityTarget.value,
        about: this.aboutTarget.value,
        linksTitle: linksTitle,
        links: links,
        slug: this.slugTarget.value,
      })
      .then(function (response) {
        notyf.success("Успешно обновлено");
      })
      .catch(function (error) {
        notyf.error("Попробуйте еще раз");
      });
  }

  notyf() {
    return new Notyf({
      duration: 3000,
      position: { x: "left", y: "bottom" },
      ripple: true,
      dismissible: true,
    });
  }
}

class Link {
  constructor(title, link) {
    this.title = title;
    this.link = link;
  }
}
