import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

export default class extends Controller {
  static targets = ["url", "editForm"];

  connect() {}
  edit(event) {
    event.preventDefault();
    this.editFormTarget.style.display = "block";

    const parentElement = event.target.closest(".is-open");
    if (parentElement) {
      parentElement.classList.remove("is-open");
    }
  }

  update(event) {
    event.preventDefault();
    let form = event.target;
    fetch(form.action, {
      method: "PATCH",
      body: new FormData(form),
    })
      .then((response) => {
        if (response.ok) {
          // Optionally update the UI after successful update
          $("#commentsList").load(" #commentsList > *");
        } else {
          // Handle error response
          console.error("Failed to update comment");
        }
      })
      .catch((error) => {
        console.error("Error updating comment:", error);
      })
      .finally(() => {
        this.editFormTarget.style.display = "none";
      });
  }

  destroy(e) {
    e.preventDefault();

    var url = this.urlTarget.value;

    $.ajax({
      type: "DELETE",
      url: url,
    })
      .then((data) => {
        $("#commentsList").load(" #commentsList > *");
      })
      .catch((data) => {
        console.log(data);
      });
  }

  notyf() {
    return new Notyf({
      duration: 3000,
      position: { x: "left", y: "bottom" },
      ripple: true,
      dismissible: true,
    });
  }
}
