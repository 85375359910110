// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "../layouts/application.scss";
import "jquery-ui";
import "controllers";
import { axios } from "axios";
import "trix";
import "@rails/actiontext";

Rails.start();
Turbo.start();
ActiveStorage.start();

import "../layouts/application.scss";
require("../custom/slider");

require("../custom/form");
require("../custom/modal");
//require("../custom/profile")

require("../custom/subscribe");
require("../custom/profile");

require("../custom/home");
require("../custom/mainbuild");
require("../custom/comments");
require("../custom/projectEdit");
require("../custom/demo");
require("../custom/destroyPost");
require("../custom/exit");
require("../custom/phone");
require("../custom/donate");
require("../custom/vue/image_form");
require("../custom/vue/image_build");
require("../custom/vue/support");
require("../custom/vue/update_safe");
require("../custom/vue/verify");
require("../custom/vue/unnaproved");
require("../custom/vue/approved");
require("../custom/vue/add_card");
require("../custom/ckeditor");
require("../custom/vue/verify_project");
require("../custom/vue/donate");
require("../custom/vue/change_card_modal");
require("../custom/vue/confirm_sms");
require("../custom/vue/comment");
require("../custom/vue/fixDonate");

import { counterText } from "../custom/counter";

window.counterText = counterText;

export function removePost(e) {
  e.preventDefault();

  var form = $(this);
  var actionUrl = form.attr("action");

  $.ajax({
    type: "delete",
    url: actionUrl,
  })
    .then((data) => {
      $(".project-blog").load(" .project-blog > *");
    })
    .catch((data) => {
      console.log(data);
      notyf.error(data.responseJSON.error);
    })
    .done(function () {});
}

window.removePost = removePost;