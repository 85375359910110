import Cropper from "cropperjs";
import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", async function () {
  try {
    let path = window.location.pathname.split("/");
    let userId;

    var notyf = new Notyf({
      duration: 100000,
      position: { x: "left", y: "bottom" },
      ripple: true,
      dismissible: true,
    });

    var setImage = document.getElementById("target").src;
    var preview = document.getElementById("target");

    $("#cover-input").change(function (e) {
      if ($(this.files) && $(this.files[0])) {
        var reader = new FileReader();
        var file = document.getElementById("cover-input").files[0];

        reader.onloadend = function () {
          preview.src = reader.result;
        };

        if (file) {
          reader.readAsDataURL(file);
          setTimeout(() => {
            goImage(preview);
          }, 500);
        } else {
          preview.src = "";
        }
      }
    });

    $("#avatar-input").change((e) => {
      var element = document.getElementById("avatar-input");
      if ($(element.files) && $(element.files[0])) {
        var reader = new FileReader();
        var file = element.files[0];
        var preview = document.getElementById("modalImg");

        reader.onloadend = function () {
          preview.src = reader.result;
          window.cropper.replace(preview.src);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      }
    });

    function goAvatar(image) {
      alert("a)");
    }

    function goImage(image) {
      var cropper = new Cropper(image, {
        autoCrop: true,
        viewMode: 3,
        dragMode: "move",
        autoCropArea: 1,
        restore: true,
        modal: false,
        guides: false,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
      });

      $("#addCover").toggle();
      $(".popup-cover-wrap").toggle();
      $("#toggleDiv").toggle();

      $("#saveCover").click(function () {
        var canvas = cropper.getCroppedCanvas({});
        $("#saveCover").attr("id", "addCover");

        canvas.toBlob(function (blob) {
          var url = URL.createObjectURL(blob);
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64data = reader.result;
            $.ajax({
              url: "/update_cover",
              method: "post",
              data: { image: base64data },
              success: function (data) {
                notyf.success("Успешно обновлено");
                setTimeout(() => {
                  Turbo.visit(window.location.href);
                }, 1500);
              },
            });
          };
        });
      });
      $("#cancelCover").click(function () {
        if (cropper) {
          Turbo.visit(window.location.href);
        }
      });
    }

    if (path[1] == "users") {
      userId = path[2];
    }

    const currentUser = await axios
      .get("/getme")
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return 0;
      });

    console.log(currentUser);
    console.log(userId);

    if (currentUser.id == userId || currentUser.slug == userId) {
      profileCover();
      avatarPopup();
    }

    // Попап обложки профиля
    function profileCover() {
      $(".header-middle-edit").on("click", function () {
        $(".popup-cover").toggleClass("active");
      });
      $(".popup-cover").on("click", function (e) {
        if (!$(e.target).closest(".popup-cover-wrap").length) {
          $(".popup-cover").toggleClass("active");
        }
      });
      $(".popup-cover-close").on("click", function () {
        $(".popup-cover").toggleClass("active");
      });

      // $("#cover-input").change(function (e) {
      //   if ($(this.files) && $(this.files[0])) {
      //     var reader = new FileReader();
      //     var file = document.getElementById("cover-input").files[0];

      //     reader.onloadend = function () {
      //       if (headerCropper == null) {
      //         return;
      //       }
      //       headerCropper.replace(URL.createObjectURL(file))
      //     };

      //     if (file) {
      //       reader.readAsDataURL(file);
      //     } else {
      //       preview.src = "";
      //     }
      //   }
      // });
    }

    // Попап аватарки
    function avatarPopup() {
      $(".header-info-photo").on("click", function () {
        $(".popup-avatar").toggleClass("active");
        if ($(".popup-avatar").hasClass("active")) {
          var preview = document.getElementById("modalImg");
          window.cropper = new Cropper(preview, {
            viewMode: 3,
            aspectRatio: 1,
            dragMode: "crop",

            minContainerHeight: 208,
          });
        }

        $("#avatar").submit(function (event) {
          if (window.cropper == null) {
            return;
          }

          var dataURL = window.cropper.getCroppedCanvas().toDataURL();
          var blobBin = atob(dataURL.split(",")[1]);
          var array = [];
          for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
          }
          var file = new Blob([new Uint8Array(array)], { type: "image/png" });
          var formData = new FormData();

          formData.append("file", file);

          $.ajax({
            url: "/update_avatar",
            method: "post",
            data: formData,

            contentType: false,
            processData: false,

            success: function (response) {
              Turbo.visit(window.location.href);
            },
          });
          event.preventDefault();
        });
      });
      $(".popup-avatar").on("click", function (e) {
        // if (!$(e.target).closest('.popup-avatar-wrap').length) {
        //     $('.popup-avatar').toggleClass('active');
        // }
        // console.log(e.target);
      });
      $(".popup-avatar-close, .popup-avatar-close-desctop").on(
        "click",
        function () {
          $(".popup-avatar").toggleClass("active");
        }
      );
    }
  } catch (e) {
    return;
  }
});
// Попап профиля
function profilePopup() {
  $(".header-profile").on("click", function () {
    $(".popup-profile").toggle();
  });
  $(".popup-profile").on("click", function (e) {
    if (!$(e.target).closest(".popup-profile-wrap").length) {
      $(".popup-profile").toggle();
    }
  });
}

// Попап подписчиков
function subscribersPopup() {
  // Используем делегирование событий для .header-info-subs
  $(document).on("click", ".header-info-subs", function () {
    $(".popup-subs").toggleClass("active");
  });

  // Используем делегирование событий для .popup-subs
  $(document).on("click", ".popup-subs", function (e) {
    if (!$(e.target).closest(".popup-subs-wrap").length) {
      console.log(e.target);
      $(".popup-subs").toggleClass("active");
    }
  });

  // Используем делегирование событий для .popup-subs-close и .popup-subs-back
  $(document).on("click", ".popup-subs-close, .popup-subs-back", function () {
    $(".popup-subs").toggleClass("active");
  });
}

$(document).ready(function () {
  subscribersPopup();
  profilePopup();
});

// Инициализация при загрузке страниц через Turbo (например, Turbo Links)
document.addEventListener("turbo:load", function () {
  subscribersPopup();
  profilePopup();
});
