const store = new Vuex.Store({
  state: {
    currentUser: null,
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
  },
  actions: {
    fetchCurrentUser({ commit }) {
      return axios
        .get("/api/get_me")
        .then((response) => {
          commit("setCurrentUser", response.data);
        })
        .catch((error) => {
          console.error("Ошибка при получении текущего пользователя:", error);
        });
    },
  },
  getters: {
    currentUser: (state) => state.currentUser,
  },
});

Vue.component("donate-block", {
  props: ["comment", "resourceType", "resourceId"],
  computed: {
    ...Vuex.mapGetters(["currentUser"]),
    isCurrentUser() {
      return (
        this.currentUser &&
        this.currentUser.id === (this.comment.donator_id || null)
      );
    },
    canEdit() {
      return this.isCurrentUser;
    },
  },
  data() {
    return {
      subcomments: [],
      showSubcomments: false,
      replyText: "",
      showReplyBox: false,
      subcommentsPage: 1,
      subcommentsLoading: false,
      subcommentsHasMore: true,
    };
  },
  methods: {
    fetchSubcomments() {
      if (!this.showSubcomments) {
        this.loadSubcomments();
      }
      this.showSubcomments = true;
    },
    toggleSubcomments() {
      this.showSubcomments = false;
    },
    loadSubcomments() {
      if (this.subcommentsLoading || !this.subcommentsHasMore) return;

      this.subcommentsLoading = true;

      axios
        .get(
          `http://localhost:3000/api/projects/${this.resourceId}/donations/${this.comment.id}/comments/`,
          {
            params: { page: this.subcommentsPage },
          }
        )
        .then((response) => {
          const { comments, total_pages } = response.data;

          const newSubcomments = comments.filter(
            (sub) =>
              !this.subcomments.some((existingSub) => existingSub.id === sub.id)
          );

          this.subcomments.push(...newSubcomments);

          if (this.subcommentsPage >= total_pages) {
            this.subcommentsHasMore = false;
          }

          this.subcommentsPage += 1;
        })
        .catch((error) => {
          console.error("Ошибка при загрузке сабкомментариев:", error);
        })
        .finally(() => {
          this.subcommentsLoading = false;
        });
    },
    toggleReplyBox() {
      if (this.currentUser) {
        this.showReplyBox = !this.showReplyBox;
      }
    },
    submitReply() {
      if (this.replyText.trim() !== "") {
        axios
          .post(
            `/api/${this.resourceType}/${this.resourceId}/donations/${this.comment.id}/comments/`,
            {
              comment: { body: this.replyText },
            }
          )
          .then((response) => {
            if (!this.subcomments.some((sub) => sub.id === response.data.id)) {
              this.subcomments.push(response.data);
            }

            this.comment.subcomments_count += 1;
            this.replyText = "";
            this.showReplyBox = false;
            this.showSubcomments = true;
          })
          .catch((error) => {
            console.error("Ошибка при отправке ответа:", error);
          });
      }
    },
    upvote() {
      axios
        .post(
          `http://localhost:3000/api/${this.resourceType}/${this.resourceId}/comments/${this.comment.id}/upvote`
        )
        .then((response) => {
          this.comment.weighted_score = response.data.message;
        })
        .catch((error) => {
          console.error("Ошибка при голосовании за комментарий:", error);
        });
    },
    downvote() {
      axios
        .post(
          `http://localhost:3000/api/${this.resourceType}/${this.resourceId}/comments/${this.comment.id}/downvote`
        )
        .then((response) => {
          this.comment.weighted_score = response.data.message;
        })
        .catch((error) => {
          console.error("Ошибка при голосовании против комментария:", error);
        });
    },
    handleRemoveSubcomment(commentId) {
      this.subcomments = this.subcomments.filter((sub) => sub.id !== commentId);
      this.comment.subcomments_count -= 1;
    },
    deleteComment() {
      if (this.currentUser) {
        axios
          .delete(
            `/api/${this.resourceType}/${this.resourceId}/comments/${this.comment.id}`
          )
          .then(() => {
            if (this.comment.parent_id) {
              this.$emit("remove-subcomment", this.comment.id);
            } else {
              this.$parent.removeComment(this.comment.id);
            }
          })
          .catch((error) => {
            console.error("Ошибка при удалении комментария:", error);
          });
      }
    },

    getCommentLabel(count) {
      const lastDigit = count % 10;
      const lastTwoDigits = count % 100;

      if (lastDigit === 1 && lastTwoDigits !== 11) {
        return "комментарий";
      } else if (
        lastDigit >= 2 &&
        lastDigit <= 4 &&
        (lastTwoDigits < 12 || lastTwoDigits > 14)
      ) {
        return "комментария";
      } else {
        return "комментариев";
      }
    },
    donatorName(comment) {
      if (comment.donator && !comment.anonim) {
        return comment.donator.full_name;
      } else if (comment.anonim) {
        return "Анонимный донат";
      } else {
        return comment.donator_name || "Анонимный донат";
      }
    },
    userLink(comment) {
      if (comment.donator && !comment.anonim) {
        return `/users/${comment.donator.id}`;
      }
    },
    getDonateLabel(count) {
      if (!count) {
        return;
      }

      const lastDigit = count % 10;
      const lastTwoDigits = count % 100;

      if (lastDigit === 1 && lastTwoDigits !== 11) {
        return "донат";
      } else if (
        lastDigit >= 2 &&
        lastDigit <= 4 &&
        (lastTwoDigits < 12 || lastTwoDigits > 14)
      ) {
        return "доната";
      } else {
        return "донатов";
      }
    },
  },
  template: `

  <div class="comment-block">
  <div class="comment-block-item">
    <div class="comment-block-item-person">
      <img 
        v-if="comment.donator_id && comment.donator.avatar_url && !comment.anonim" 
        :src="comment.donator.avatar_url" 
        alt="Avatar" 
        />
      <img 
        v-else 
        src="https://boring-avatars-api.vercel.app/api/avatar?size=40&variant=marble" 
        alt="Default Avatar" 
        />
    </div>
    <div class="comment-block-item-content">
      <div class="comment-block-item-header">
        <div class="comment-block-item-info">
          <div class="comment-block-item-left">
            <div class="comment-block-item-namewrapper">
              <a  :href="userLink(comment)"  class="comment-block-item-name topic-starter">
              {{ donatorName(comment) }}
              </a>
              <span v-if="comment.donator && comment.donator.donats_count && !comment.anonim" class="comment-block-item-donates">{{ comment.donator && comment.donator.donats_count }} {{ getDonateLabel(comment.donator.donats_count) }}</span>
            </div>
            <div class="comment-block-item-time">
              <span class="comment-block-time-clock">{{ comment.formatted_created_at }}</span>
            </div>
          </div>
        </div>
        <div class="comment-block-item-amount">{{ comment.formatted_net_amount }} ₽
        </div>
      </div>
      <div class="comment-block-item-text">{{ comment.comment }}</div>
      <div class="comment-block-item-footer">
        <div class="likes-group">
          <button class="comment-block-item-votebtn" @click="upvote">
            <svg data-like-target="upvote" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#6b7280" class="bi bi-caret-up" viewBox="0 0 16 16">
              <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z"></path>
            </svg>
          </button>
          <div class="likes-count">{{ comment.weighted_score }}</div>
          <button class="comment-block-item-votebtn" @click="downvote">
            <svg data-like-target="downvote" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#6b7280" class="bi bi-caret-down" viewBox="0 0 16 16">
              <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"></path>
            </svg>
          </button>
          <button class="likes-answer comment-block-item-votebtn" area-disabled="true" v-if="currentUser" @click="toggleReplyBox" class="comment-block-item-votebtn likes-answer" >{{ showReplyBox ? 'Отмена' : 'Ответить' }}
          </button>
          <button class="likes-expand comment-block-item-votebtn " area-disabled="true" v-if="comment.comments_count > 0 && !showSubcomments" @click="fetchSubcomments">
            <span class="likes-expand-inner">
              <span class="likes-expand-text">{{ comment.comments_count }} {{ getCommentLabel(comment.comments_count) }}
              </span>
              <span class="likes-expand-icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.9998 7.91797L9.99992 12.9179L5 7.91797" stroke="#2563EB" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </span>
          </button>
          <button class="likes-expand active comment-block-item-votebtn " area-disabled="true" v-if="showSubcomments" @click="toggleSubcomments">
            <span class="likes-expand-inner">
              <span class="likes-expand-text">{{ comment.comments_count }}  {{ getCommentLabel(comment.comments_count) }}
              </span>
              <span class="likes-expand-icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.9998 7.91797L9.99992 12.9179L5 7.91797" stroke="#2563EB" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </span>
          </button>
        </div>
      </div>
      <div v-if="showReplyBox" class="reply-box">
        <textarea v-model="replyText" class="comment-block-textarea" placeholder="Введите ваш комментарий"></textarea>
        <button @click="submitReply" class="comment-block-item-actionbtn" >Отправить</button>
      </div>
    </div>
  </div>
  <div v-if="showSubcomments" class="subcomments">
    <comment-block
      v-for="subcomment in subcomments"
      :key="subcomment.id"
      :comment="subcomment"
      :resource-type="resourceType"
      :resource-id="resourceId"
      @remove-subcomment="handleRemoveSubcomment"
      ></comment-block>
    <div v-if="subcommentsLoading" class="loading">Загрузка...</div>
    <button v-if="subcommentsHasMore && !subcommentsLoading && subcomments.length < comment.subcomments_count" @click="loadSubcomments" class="comment-block-item-actionbtn">
    Загрузить еще
    </button>
  </div>
</div>
  `,
});

$(document).on("turbo:load", function () {
  let element = document.getElementById("donatsVue");

  if (element == null) {
    return;
  }

  new Vue({
    el: "#donatsVue",
    store, // Подключение Vuex store
    data() {
      return {
        resourceType: null,
        resourceId: null,
        comments: [],
        page: 1,
        loading: false,
        hasMoreComments: true,
        newCommentText: "",
        showSubmitButton: false,
        isMouseDownOnButton: false,
        sort: "date",
      };
    },
    computed: {
      ...Vuex.mapGetters(["currentUser"]),
      canSubmitComment() {
        return this.currentUser != null;
      },
    },
    mounted() {
      this.resourceType = this.$el.getAttribute("data-resource-type");
      this.resourceId = this.$el.getAttribute("data-resource-id");

      // Получить текущего пользователя
      this.$store.dispatch("fetchCurrentUser").then(() => {
        this.loadMoreComments();
      });
    },
    methods: {
      loadMoreComments() {
        console.log(1);
        if (this.loading || !this.hasMoreComments) return;

        this.loading = true;

        axios
          .get(`/api/projects/${this.resourceId}/donations`, {
            params: { page: this.page, sort: this.sort },
          })
          .then((response) => {
            const { donates, total_pages } = response.data;
            this.comments.push(...donates);

            if (this.page >= total_pages) {
              this.hasMoreComments = false;
            }

            this.page += 1;
          })
          .catch((error) => {
            console.error("Ошибка при загрузке комментариев:", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      changeSort(newSort) {
        this.sort = newSort;
        this.page = 1;
        this.comments = [];
        this.loadMoreComments();
      },
      submitComment() {
        if (this.newCommentText.trim() !== "") {
          axios
            .post(
              `http://localhost:3000/api/${this.resourceType}/${this.resourceId}/comments`,
              {
                comment: { body: this.newCommentText },
              }
            )
            .then((response) => {
              this.comments.unshift(response.data);
              this.newCommentText = "";
              this.showSubmitButton = false;
            })
            .catch((error) => {
              console.error("Ошибка при отправке комментария:", error);
            });
        }
      },
      handleTextareaFocus() {
        if (this.canSubmitComment) {
          this.showSubmitButton = true;
        }
      },
      handleTextareaBlur(event) {
        if (!this.isMouseDownOnButton) {
          this.showSubmitButton = false;
        }
      },
      handleButtonMouseDown() {
        this.isMouseDownOnButton = true;
      },
      handleButtonMouseUp() {
        this.isMouseDownOnButton = false;
      },
      removeComment(commentId) {
        this.comments = this.comments.filter(
          (comment) => comment.id !== commentId
        );
      },
      handleRemoveSubcomment(commentId) {
        console.log(commentId);
        console.log(this.subcomments);
      },
    },
  });
});
