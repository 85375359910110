import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });
 
  $("#exitPc, #exitMb").click(function (e) {
   e.preventDefault();


   $.ajax({
    type: "post",
     url: "/exit",
   })
     .then((data) => {
       Turbo.visit("/");
     })
     .catch((data) => {
       console.log(data);
       notyf.error("Попробуйте еще раз");
     })
     .done(function () {});
 });

});
