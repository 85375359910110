import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  $("#subscribe").submit(function (e) {
    e.preventDefault();

    var form = $(this);
    var actionUrl = form.attr("action");

    $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(),
    })
      .then((data) => {
        notyf.success("Вы успешно подписаны");
      })
      .catch((data) => {
        notyf.error(data.responseJSON.error);
      });
  });

});
