import { Notyf } from "notyf";
//import "notyf/notyf.min.css";

$(document).on("turbo:load", function () {
  var notyf = new Notyf({
    duration: 3000,
    position: { x: "left", y: "bottom" },
    ripple: true,
    dismissible: true,
  });

  let element = document.getElementById("editSafe");

  if (element == null) {
    return;
  }

  let token = document.querySelector("input[name=token]");

  let app = new Vue({
    el: "#editSafe",
    data: {
      password: null,
      password_confirmation: null,
      token: token.value,
    },

    mounted() {
      $("#editPassSpan, .settings-inner-form-cancel").on(
        "click",
        function () {
          $(
            ".settings-inner-form-changepass, .settings-inner-form-group-wrap"
          ).toggle();
        }
      );
    },

    methods: {
      send() {
        const myInterceptor = axios.interceptors.request.use(
          function (config) {
            $("#sup-send").html(
              '<i class="fa fa-circle-o-notch fa-spin"></i> Подождите'
            );

            return config;
          },
          function (error) {
            notyf.error("Пожалуйста попробуйте позже");
            // Do something with request error
            return Promise.reject(error);
          }
        );

        const formData = {
          password: this.password,
          password_confirmation: this.password_confirmation,
          authenticity_token: this.token,
        };

        axios
          .post("/update_safe", formData)
          .then((res) => {
            notyf.dismissAll();
            axios.interceptors.request.eject(myInterceptor);
            notyf.success("Успешно");
            $("#sup-send").html("Отправить");
          })
          .catch(function (error) {
            $("#sup-send").html("Отправить");
            console.log(error.response.data);
            notyf.dismissAll();
            notyf.error(error.response.data);
          });
      },
    },
  });
});
